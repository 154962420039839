'use client';
import { classNames } from '@/util/classNames';
import { Route } from 'next';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import NProgress from 'nprogress';
import { forwardRef } from 'react';
import interactionResponse from 'await-interaction-response';

let shouldPrefetch = true;
if (typeof window !== 'undefined') {
  if ('connection' in window?.navigator) {
    if (window?.navigator?.connection?.saveData === true) {
      shouldPrefetch = false;
    }
  }
}

type Props<T extends string> = {
  children: any;
  href: Route<T> | URL;
  onClick?: (href: string) => void;
  fakeLink?: boolean;
  className?: string;
  key?: string | number;
  title?: string;
  tag?: 'div' | 'article';
  button?: boolean;
  dataGoogleInterstitial?: boolean;
};

export default forwardRef(function PFLink<T extends string>(
  props: Props<T>,
  ref,
) {
  const {
    href,
    children,
    onClick,
    fakeLink,
    tag = 'div',
    className,
    button,
    dataGoogleInterstitial,
    ...rest
  } = props;
  const pathname = usePathname();
  const router = useRouter();

  const Tag = tag;

  if (fakeLink) {
    return (
      <Tag
        {...rest}
        className={classNames(
          button
            ? 'w-full border-none bg-tintColor p-3 m-5 text-base font-semibold text-white rounded-full text-center self-center hover:bg-tintColorHover hover:text-white sm:max-w-[320px]'
            : className,
          'cursor-pointer',
        )}
        ref={ref}
        onClick={(event) => {
          event.stopPropagation();
          if (pathname.toString() !== href) {
            NProgress.start();
          }

          interactionResponse().then(() => {
            if (pathname.toString() !== href) {
              window?.adHandler?.service?.destroyPageType();
            }

            if (onClick) {
              onClick(href);
            }
            router.push(href);
          });
        }}
        onTouchStart={() => {
          if (shouldPrefetch) {
            router.prefetch(href);
          }
        }}
        onMouseEnter={() => {
          if (shouldPrefetch) {
            router.prefetch(href);
          }
        }}
        data-google-interstitial={dataGoogleInterstitial}
      >
        {children}
      </Tag>
    );
  }

  return (
    <Link href={href} legacyBehavior prefetch={shouldPrefetch}>
      <a
        ref={ref}
        onClick={(event) => {
          event.stopPropagation();
          if (pathname.toString() !== href) {
            NProgress.start();
          }

          interactionResponse().then(() => {
            if (pathname.toString() !== href) {
              window?.adHandler?.service?.destroyPageType();
            }

            if (onClick) {
              onClick(href);
            }
          });
        }}
        className={
          button
            ? 'w-full border-none bg-darkPink p-3 m-5 text-base font-semibold text-white rounded-full text-center self-center hover:bg-tintColor hover:text-white sm:max-w-[320px]'
            : className
        }
        {...rest}
        data-google-interstitial={dataGoogleInterstitial}
      >
        {children}
      </a>
    </Link>
  );
});
