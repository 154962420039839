import { classNames } from '@/util/classNames';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  tag?: 'h1' | 'h2' | 'h3' | 'div';
  className?: string;
  letterSpacing?: 'base' | 'small' | 'wide';
  size?: 'bigger' | 'base' | 'small' | 'xxs';
};

export function PFText(props: Props): JSX.Element {
  const { tag = 'div', letterSpacing = 'base', className, size } = props;
  const Tag = tag;

  return (
    <Tag
      className={classNames(
        letterSpacing === 'base' && 'tracking-wide',
        letterSpacing === 'wide' && 'tracking-[0.2em]',
        letterSpacing === 'small' && 'tracking-[0.1em]',
        size === 'xxs' && 'text-[12px] leading-4',
        size === 'small' && 'text-sm leading-4 sm:text-base sm:leading-5',
        size === 'base' && 'text-base sm:text-[18px] sm:leading-6',
        size === 'bigger' && 'text-[18px] leading-6',
        className,
      )}
    >
      {props.children}
    </Tag>
  );
}
